import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 339.000000 344.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,344.000000) scale(0.100000,-0.100000)">
          
          <path d="M1337 3173 c-4 -3 -7 -14 -7 -24 0 -9 -7 -30 -15 -46 -8 -15 -53
-149 -101 -298 -47 -148 -108 -335 -134 -415 -118 -358 -170 -523 -165 -528
11 -12 249 -32 451 -38 l221 -7 27 84 c49 152 58 172 67 158 5 -8 25 -65 45
-126 20 -62 40 -110 45 -107 12 7 11 61 -1 84 -7 13 -6 19 5 24 18 7 20 26 2
26 -10 0 -13 24 -12 98 0 95 -5 137 -21 176 -5 15 -4 17 6 11 9 -5 11 -4 6 3
-4 7 -4 27 -1 46 5 27 11 33 23 28 14 -5 15 -3 4 10 -7 8 -9 24 -5 34 6 17 7
16 15 -2 6 -15 7 -13 3 7 -3 16 10 68 31 130 49 142 72 211 96 294 11 39 31
102 43 140 47 143 65 206 65 225 0 20 -8 20 -343 20 -189 0 -347 -3 -350 -7z
m410 -1085 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m0 -50 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M1897 2646 c-3 -8 -2 -27 3 -43 7 -25 8 -23 7 15 -1 46 -2 49 -10 28z"/>
<path d="M2602 2067 c86 -32 154 -72 169 -99 10 -19 8 -25 -16 -44 -45 -36
-212 -80 -380 -100 -22 -3 -89 -12 -150 -20 -154 -22 -756 -30 -1024 -14 -371
21 -641 83 -641 146 0 23 48 62 112 91 31 15 56 28 54 30 -5 5 -107 -28 -138
-46 -18 -9 -40 -29 -51 -43 -17 -23 -18 -31 -8 -56 25 -59 95 -100 241 -140
354 -97 853 -124 1330 -72 421 46 664 121 715 221 14 26 14 32 0 59 -8 16 -26
37 -40 46 -30 19 -135 52 -194 59 -44 6 -43 5 21 -18z"/>
<path d="M767 1438 c-47 -144 -88 -273 -92 -285 l-6 -23 344 0 345 0 16 43
c26 69 146 441 146 453 0 7 -54 13 -157 17 -87 4 -189 11 -228 17 -38 6 -104
15 -145 20 -41 6 -89 13 -107 16 l-31 6 -85 -264z"/>
<path d="M1830 1622 c0 -11 89 -254 95 -260 7 -8 6 1 -4 34 -34 104 -65 210
-63 216 1 3 -5 9 -13 12 -8 3 -15 2 -15 -2z"/>
<path d="M1937 1315 c3 -22 12 -51 19 -65 l14 -25 -7 30 c-3 17 -12 46 -19 65
l-14 35 7 -40z"/>
<path d="M1023 1049 c-12 -4 -34 -26 -49 -48 -16 -22 -31 -40 -34 -40 -3 0
-20 20 -38 44 -29 41 -37 45 -74 45 l-40 0 28 -37 c15 -21 43 -58 61 -82 29
-39 33 -51 33 -107 l0 -64 30 0 30 0 0 63 c0 62 1 66 59 143 33 43 58 81 55
83 -8 8 -40 8 -61 0z"/>
<path d="M1163 1052 c-29 -3 -33 -7 -33 -33 0 -24 4 -29 25 -29 25 0 25 -1 25
-85 0 -84 0 -85 -25 -85 -21 0 -25 -5 -25 -30 l0 -30 85 0 85 0 0 30 c0 25 -4
30 -25 30 -25 0 -25 1 -25 85 0 84 0 85 25 85 21 0 25 5 25 29 0 28 -2 29 -52
33 -29 1 -68 2 -85 0z"/>
<path d="M1690 1030 l0 -30 105 0 105 0 0 -85 0 -85 -70 0 -70 0 0 55 0 55
-35 0 -35 0 0 -90 0 -90 128 0 c86 0 132 4 140 12 13 13 18 242 6 272 -5 14
-27 16 -140 16 l-134 0 0 -30z"/>
<path d="M2109 1013 c-29 -68 -99 -242 -99 -248 0 -3 15 -5 34 -5 25 0 35 5
39 20 5 18 14 20 77 20 61 0 74 -3 89 -21 12 -15 25 -20 48 -17 l31 3 -19 40
c-10 22 -38 87 -63 145 -44 104 -45 105 -80 108 -35 3 -37 1 -57 -45z m86
-105 l15 -38 -44 0 -45 0 16 38 c8 20 17 41 20 45 7 12 20 -3 38 -45z"/>
<path d="M2290 1030 c0 -28 2 -30 39 -30 22 0 42 -4 45 -8 3 -5 6 -59 7 -120
l1 -112 34 0 33 0 3 118 3 117 48 3 c45 3 47 5 47 33 l0 29 -130 0 -130 0 0
-30z"/>
<path d="M2582 932 c-72 -170 -72 -172 -34 -172 21 0 35 7 42 20 9 17 21 20
80 20 59 0 72 -3 82 -20 16 -26 84 -29 74 -4 -3 8 -31 76 -63 150 l-56 134
-36 0 -36 0 -53 -128z m116 -17 l22 -45 -46 0 c-40 0 -45 2 -39 18 4 9 12 29
17 45 5 15 13 27 17 27 4 0 17 -20 29 -45z"/>
<path d="M623 993 c-12 -32 -37 -96 -57 -143 -20 -46 -36 -86 -36 -87 0 -2 13
-3 30 -3 19 0 33 7 40 20 9 17 21 20 85 20 64 0 76 -3 85 -20 12 -23 76 -30
67 -7 -3 6 -30 71 -61 142 l-55 130 -38 3 c-38 3 -38 3 -60 -55z m90 -85 c9
-20 13 -39 9 -42 -3 -3 -23 -6 -44 -6 -36 0 -39 2 -32 23 13 43 36 79 44 71 4
-5 15 -26 23 -46z"/>
<path d="M1355 1039 c-4 -6 -7 -41 -6 -77 1 -87 10 -94 119 -90 l82 3 0 -27 0
-28 -100 0 -100 0 0 -30 0 -30 123 0 c82 0 127 4 135 12 16 16 16 140 0 156
-8 8 -46 12 -105 12 -93 0 -93 0 -93 25 l0 25 105 0 105 0 0 30 0 30 -129 0
c-82 0 -132 -4 -136 -11z"/>
<path d="M670 607 c0 -7 7 -22 15 -33 8 -10 15 -36 15 -57 0 -31 3 -38 18 -35
12 2 19 16 23 45 3 23 11 48 18 57 22 27 7 41 -17 16 l-22 -24 -20 22 c-24 25
-30 27 -30 9z"/>
<path d="M1015 608 c-2 -7 -6 -31 -7 -53 -2 -33 -7 -41 -25 -43 -25 -4 -39 30
-31 73 4 17 1 25 -8 25 -18 0 -19 -91 -2 -108 18 -18 63 -14 81 6 19 21 24
112 7 112 -6 0 -13 -6 -15 -12z"/>
<path d="M1060 555 c0 -51 3 -65 15 -65 9 0 15 9 15 23 1 19 2 20 11 5 5 -10
18 -22 27 -27 16 -8 18 -3 19 49 2 57 1 59 -29 69 -55 19 -58 17 -58 -54z m63
10 c1 -5 -6 -11 -15 -13 -11 -2 -18 3 -18 13 0 17 30 18 33 0z"/>
<path d="M1353 613 c-9 -3 -13 -26 -13 -67 l0 -62 38 4 c20 2 43 9 50 14 8 7
12 7 12 -1 0 -8 14 -11 38 -9 30 2 38 7 40 25 2 16 -4 26 -22 35 -28 12 -37
34 -10 24 23 -9 37 3 23 20 -25 31 -89 5 -75 -31 3 -8 17 -20 31 -25 41 -16
29 -25 -35 -27 -34 -1 -60 2 -60 7 0 6 9 10 20 10 11 0 20 7 20 15 0 8 -9 15
-20 15 -27 0 -25 18 2 22 35 5 35 35 1 35 -15 0 -34 -1 -40 -4z"/>
<path d="M1533 607 c-3 -5 1 -15 10 -22 11 -8 17 -27 17 -54 0 -34 3 -41 20
-41 17 0 20 7 20 50 0 35 4 49 13 48 6 -2 12 4 12 12 0 17 -82 24 -92 7z"/>
<path d="M1935 612 c-11 -11 -5 -32 10 -32 11 0 15 -12 15 -49 0 -44 2 -49 20
-44 16 4 20 14 20 49 0 24 5 44 10 44 6 0 10 9 10 19 0 16 -7 20 -40 19 -21 0
-41 -3 -45 -6z"/>
<path d="M2222 611 c-10 -7 -9 -118 1 -128 5 -4 10 2 13 14 4 13 17 23 35 27
32 6 49 46 30 70 -13 15 -65 26 -79 17z m58 -46 c0 -8 -9 -15 -20 -15 -11 0
-20 7 -20 15 0 8 9 15 20 15 11 0 20 -7 20 -15z"/>
<path d="M2336 613 c-3 -3 -6 -32 -6 -64 l0 -57 40 -4 c28 -2 40 0 40 9 0 7
-11 13 -25 13 -23 0 -25 3 -25 49 0 47 -9 68 -24 54z"/>
<path d="M797 592 c-20 -22 -22 -73 -5 -90 21 -21 85 -15 102 10 22 31 20 54
-6 78 -27 25 -70 26 -91 2z m78 -32 c4 -12 1 -27 -5 -35 -27 -33 -78 -5 -65
35 4 14 15 20 35 20 20 0 31 -6 35 -20z"/>
<path d="M1220 547 l0 -62 44 3 c53 5 62 19 44 71 -15 43 -24 51 -60 51 -28 0
-28 -1 -28 -63z m50 23 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m18 -47 c2 -8 -5 -13 -17 -13 -12 0 -21 6 -21 16 0 18
31 15 38 -3z"/>
<path d="M1699 603 c-1 -5 -2 -30 -3 -57 -1 -40 2 -50 19 -56 36 -13 94 22 95
58 0 24 -48 62 -80 62 -16 0 -30 -3 -31 -7z m69 -52 c4 -23 -11 -41 -35 -41
-8 0 -13 14 -13 36 0 31 3 35 23 32 14 -2 23 -11 25 -27z"/>
<path d="M2056 557 c-33 -72 -33 -82 1 -60 29 19 29 19 56 -4 31 -29 32 -4 0
60 l-30 62 -27 -58z"/>
<path d="M2420 562 c0 -56 12 -72 55 -72 43 0 55 16 55 72 0 36 -4 48 -15 48
-11 0 -15 -12 -15 -45 0 -32 -5 -47 -15 -51 -25 -10 -45 16 -45 57 0 22 -4 39
-10 39 -5 0 -10 -22 -10 -48z"/>
<path d="M2572 594 c-26 -18 -30 -65 -6 -88 16 -17 70 -21 91 -8 6 4 16 20 22
35 10 27 10 27 -29 27 -40 0 -54 -15 -25 -26 24 -9 18 -24 -10 -24 -31 0 -52
34 -34 56 6 8 27 14 45 14 35 0 46 13 18 24 -26 10 -48 7 -72 -10z"/>
<path d="M1838 546 c-30 -60 -27 -73 9 -49 23 15 27 15 54 -1 16 -9 29 -15 29
-12 0 11 -54 116 -60 116 -3 0 -18 -24 -32 -54z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
